import Post from 'components/ContentItem/Post';
import useTimeAgo from 'hooks/useTimeAgo';
import { ArticlePreview } from 'interfaces/content/articles/Articles';
import { EventData } from 'services/Gtm/entities';
import { ARTICLE_PAGE_TEST_IDS } from 'constants/testsIds/articlePage';
import { Wrapper, TimeAgo, PostWrapper, Title, LatestHeading, PostList } from './LatestStyles';

interface Props {
  content: {
    posts: ArticlePreview[];
  };
  trackingData: EventData;
  title: string;
}

const LatestPopularContent = ({ content, trackingData, title }: Props) => {
  if (content.posts.length === 0) return null;
  const type = 'article-redesign-latest';
  return (
    <Wrapper className='latest-popular-module'>
      <LatestHeading className='heading'>
        <Title
          as='h2'
          $fontFamily='saol'
          $size={{ default: 'level2' }}
          className='latest-popular-title'
        >
          {title}
        </Title>
      </LatestHeading>
      <PostList
        data-cy={ARTICLE_PAGE_TEST_IDS.POST_LIST}
        className='latest-popular-content'
      >
        {content.posts.map((post: ArticlePreview) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const date = useTimeAgo(post.date);
          return (
            <PostWrapper
              key={post.title}
              className='content-wrapper'
            >
              <TimeAgo className='time-ago-wrapper'>{date}</TimeAgo>
              <Post
                as='div'
                key={post.title}
                image={post.image}
                eyebrowSectionLabel={post.eyebrowSectionLabel}
                eyebrowSectionLink={post.eyebrowSectionLink}
                eyebrowTopicLabel={post.eyebrowTagLabel}
                eyebrowTopicLink={post.eyebrowTagLink}
                title={post.title}
                titleLink={post.titleLink || ''}
                authors={post.authors}
                date={post.date}
                type={type}
                trackingData={trackingData}
                className='content'
              />
            </PostWrapper>
          );
        })}
      </PostList>
    </Wrapper>
  );
};

export default LatestPopularContent;
