import CustomWindow from 'interfaces/Utils';
import { Microsites } from 'constants/constants';
import { RefObject, useContext, useEffect } from 'react';
import { UserContext } from 'providers/UserProvider';

declare let window: CustomWindow;

export interface Props {
  url: string;
  noTrinityAds: boolean;
  paywallCheck?: string;
  trinityRef: RefObject<HTMLElement>;
  microsite: string;
}

const TrinityPlayer = ({ url, noTrinityAds, paywallCheck, trinityRef, microsite }: Props) => {
  const { hasConsent } = useContext(UserContext);

  useEffect(() => {
    const script = document.createElement('script');

    if (paywallCheck !== 'pending' && hasConsent !== null) {
      const playerType = paywallCheck === 'no-paywall' ? '' : 'subscription-locked';
      const adStatus = noTrinityAds || !hasConsent ? 1 : 0;
      let id;

      switch (microsite) {
        case Microsites.Asia:
          id = 2900019104;
          break;
        case Microsites.Europe:
          id = 2900019105;
          break;
        case Microsites.Well:
          id = 2900019106;
          break;
        case Microsites.Crypto:
          id = 2900019107;
          break;
        default:
          id = 2900017491;
          break;
      }

      script.src = `https://trinitymedia.ai/player/trinity/${id}/?pageURL=${encodeURIComponent(
        url,
      )}&FAB=fabAfterClick&subscriber=${adStatus}&playerType=${playerType}`;
      script.async = true;
      script.type = 'text/javascript';

      if (trinityRef.current) {
        if (window.TRINITY_PLAYER?.api) {
          window.TRINITY_PLAYER.api.deletePlayer();
        }

        trinityRef.current.appendChild(script);
      }
    }

    return () => {
      if (script.parentNode) {
        script.parentNode.removeChild(script);
      }
    };
  }, [url, trinityRef, noTrinityAds, paywallCheck, hasConsent, microsite]);

  return (
    <div
      className='trinity-tts-pb'
      dir='ltr'
    />
  );
};

export default TrinityPlayer;
