import styled from 'styled-components';
import { font, color, breakpoint } from 'styles/globals';
import { Theme, getColor } from 'styles/theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Dropdown = styled.div<{ theme: Theme }>`
  display: none;
  position: absolute;
  width: 64px;
  min-width: 50px;
  height: auto;
  animation: slide-in 0.5s both;
  background-color: ${getColor('surfaceWhite')};
  z-index: 2;

  @keyframes slide-in {
  0% {
    transform: translateY(-10%);
  }
  100% {
    transform: translateY(0);
  }
}
  &.show {
    display: block;
  }

  @media (${breakpoint.mdMax}) {
    min-width: 160px;
    right: -80px;
    left: unset;
  }

  &.article-redesign {
    &::before {
      display: block;
      content: '';
      height: 16px;
    }
  }
`;

export const DropdownContainer = styled.div<{ theme: Theme }>`
  position: relative;
  max-width: 50px;
  text-align: center;

  &.article-redesign > [role="button"] > .share-icon {
    margin: 8px;
    fill: ${getColor('iconPrimary')};
  }

  &:hover {
    &.article-redesign {
      .share-icon {
        fill: ${getColor('iconAccentPrimary')};
      }
    }
  }

  &:focus-within {
    &.article-redesign {
      .share-icon {
        fill: ${getColor('iconSecondary')};
      }
    }
  }

  .title {
    font-family: ${font.graphikCond};
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
    letter-spacing: -0.7px;
    color: ${color.linkBlue};

    @media (${breakpoint.mmMax}) {
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 19px;
    }
  }

  svg {
    width: 29px;
    height: 32px;
    fill: ${getColor('iconPrimary')};
  }

  &:focus {
    .share-icon {
      fill: ${getColor('iconSecondary')};
    }
  }

  &:hover {
    .share-icon {
      fill: ${getColor('iconAccentPrimary')};
    }
  }

  // TODO: Remove and apply styles in their respective divs below once Article
  // Redesign is done.
  // Style overrides for divs below to fit Article Redesign in a cleaner way
  &.article-redesign {
    div {
      width: 100% !important;
    }

    div > div > .social-links-container {
      padding: 8px !important;
    }

    a {
      width: 24px !important;
      height: 24px !important;
    }
  }

  @media (${breakpoint.mdMin}) {
    &:hover, &:focus-within {
      & > ${Dropdown} {
        display: block;
      }
    }
  }
`;

export const DropdownContent = styled.div<{ theme: Theme }>`
  display: flex;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid ${getColor('borderSecondary')};

  .social-links-container {
    border-right: none;
    position: initial;
    padding: 16px;
    
    & > div:not(:last-child) {
      margin-bottom: 18px;
    }

    a {
      display: inline-block;
      width: 32px;
      height: 32px;
    }

    svg {
      width: 100%;
      height: 100%;
      object-fit: cover;
      fill: ${getColor('iconPrimary')};
    }

    @media (${breakpoint.mdMax}) {
      border-bottom: none;
      height: 40px;
      padding: 8px;

      & > div:not(:last-child) {
        margin-bottom: 0;
        margin-right: 16px;
      }

      a {
        display: inline-block;
        width: 24px;
        height: 24px;
      }
    }
  }
`;
